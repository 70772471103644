<template>
  <div class="historico-pagamento">
    <Voltar />
    <h1 class="titulo">Histórico de pagamento</h1>

    <article class="historico-pagamento--item" v-for="pagamento in state.pagamentos" :key="pagamento.id">
      <h3>Rede America Prêmios</h3>
      <p><span>Data: </span> {{ pagamento.created_at }}</p>
      <!-- <p><span>Cartão: </span> {{ pagamento.tipo }} </p> -->
      <p><span>Cupom: </span> {{ pagamento.numeros }}</p>
      <p><span>Valor total: </span> R$ {{ pagamento.valor }}</p>
    </article>
  </div>
</template>
<script>
import { reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification'
import services from '../../services'
import Voltar from '../../components/Voltar'
export default {
  setup () {
    const user = JSON.parse(window.localStorage.getItem('user'))
    const router = useRouter()
    const toast = useToast()

    const state = reactive({
      hasErrors: false,
      isLoading: false,
      pagamentos: {}
    })

    async function getPagamentos () {
      try {
        state.isLoading = true
        const { data, errors } = await services.pagamentos.index({ token: user.token })

        if (!errors) {
          state.pagamentos = data
          state.isLoading = false
          return
        }

        if (errors.status === 404) {
          toast.error('Pagamentos não encontrado')
        }

        if (errors.status === 401) {
          toast.error('Pagamentos Inválido')
        }

        if (errors.status === 422) {
          toast.error(errors.statusText)
        }
        state.isLoading = false
      } catch (error) {
        toast.error('Erro ao fazer carregar os Pagamentos!')
        state.isLoading = false
        state.hasErrors = !!error
      }
    }
    getPagamentos()

    return {
      router,
      state,
      user
    }
  },
  components: { Voltar }
}
</script>
